import * as React from "react"
import { graphql } from "gatsby"
import {Container, Row, Col} from "react-bootstrap"
// import { ProductListing } from "../../../components/product-listing"
import { Seo } from "../../../components/helmet/seo"
import slugify from "@sindresorhus/slugify"
import { MoreButton } from "../../../components/more-button"
import GridProductsLayout from "../../../components/layout/grid-products"

import {PageTitle} from '../../../styles/pages/collectionPage.style'
import layoutData from '../../../utils/layout.config.json'

export default function ProductTypeIndex({
  data: { products },
  pageContext: { productType },
}) {
  const ldata = layoutData.productType[productType] ? layoutData.productType[productType] : {layout: "row", rowCount: {"sm": "1", "md": "5", "lg": "8"}}

  return (
    <>
      <Seo title={`Category: ${productType}`} />
      <Container fluid>
        <Row className="px-2 px-md-3 mx-auto">
          <Col>
            <PageTitle >{productType}</PageTitle>
          </Col>
        </Row>

      
      
      {/* <ProductListing products={products.nodes} /> */}
      <GridProductsLayout row={ldata.rowCount.md} gridType={ldata.layout} products={products.nodes} />
      {products.pageInfo.hasNextPage && (
        <MoreButton to={`/search?p=${slugify(productType)}#more`}>
          More Products
        </MoreButton>
      )}
      </Container>
    </>
  )
}

export const query = graphql`
  query($productType: String!) {
    products: allShopifyProduct(
      filter: { productType: { eq: $productType } }
      sort: { fields: publishedAt, order: ASC }
      limit: 24
    ) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
